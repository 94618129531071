import { Controller } from 'stimulus';
import load_target_view from '../shared/load_view'

export default class extends Controller {
  connect() {
    const linkScroll = $('a[data-element-id]');
    const sections = document.querySelectorAll('.section-carmodel');
    const defaultLink = $('.link-scroll:first');
    const defaultSection = document.querySelector('.section-carmodel-default');
    let defaultSectionHeight = 0;

    if (defaultSection !== null) defaultSectionHeight = defaultSection.offsetHeight;

    window.onscroll = function(event) {
      if (window.pageYOffset >= 315) {
        $('.make-models-list .nav-bar-block .grid-container').addClass('scroll')
      } else {
        $('.make-models-list .nav-bar-block .grid-container').removeClass('scroll')
      }

      sections.forEach(sec => {
        const top = window.pageYOffset;
        const offset = sec.offsetTop - 60;
        const height = sec.offsetHeight;
        const id = sec.firstChild.id;
  
        if (top >= offset && top < offset + height) {
          defaultLink.removeClass('focus');
          linkScroll.removeClass('focus');
          $(`[data-element-id='${id}']`).addClass('focus');
        }
      })

      if (window.pageYOffset < 375 + defaultSectionHeight ) {
        linkScroll.removeClass('focus');
        defaultLink.addClass('focus');  
      }
    };
  }

  scrollToGroup(e) {
    const targetElementId = $(e.currentTarget).data('element-id');
    const targetElement = document.getElementById(targetElementId);

    targetElement.scrollIntoView({behavior: 'smooth', block: 'start'});
  }
}
