export const update_model_select_when_change_maker = (checkHomePage = false, callback) => {
  document.querySelector('.js-make-select').addEventListener('change', function (e) {
    update_model_select()
    callback?.()
    if (checkHomePage) {
      $('#ett_select_make').val(e.target.value)
    }
  })
}

export const update_model_select = () => {
  var model_data = JSON.parse(document.querySelector('.js-model-select').dataset.model)
  var make_select_box = document.querySelector('.js-make-select')
  var current_make_value = make_select_box.options[make_select_box.selectedIndex].value
  var prompt_model_options = document.querySelector('.js-model-select').firstChild.outerHTML
  document.querySelector('.js-model-select').innerHTML = ''

  if (current_make_value && current_make_value != '0') {
    const pathRegex = /^\/used_car\/[^\/]+\/(?<model>[^\/]+)\/?$/
    let modelSelected = window.location.pathname.match(pathRegex)

    if (modelSelected) {
      modelSelected = decodeURIComponent(modelSelected.slice(-1)[0].toLowerCase())
    }

    model_data[current_make_value].forEach(e => {
      if (modelSelected && modelSelected === e[1]) {
        prompt_model_options += `<option value="${e[1]}" selected>${e[0]}</option>`
      } else {
        prompt_model_options += `<option value="${e[1]}">${e[0]}</option>`
      }
    })
  }

  document.querySelector('.js-model-select').innerHTML = prompt_model_options
}

const FIELDMAPPING = {
  make: 'maker_options',
  model: 'model_options',
  fid: 'regis_year_from',
  jid: 'regis_year_to',
  prcf: 'fob_options_min',
  prct: 'fob_options_max',
  sds: 'engine_options_from',
  eds: 'engine_options_to',
  mimn: 'mileage_options_from',
  mimx: 'mileage_options_to',
  smo: 'regis_month_from',
  emo: 'regis_month_to',
  ac: 'accident_options',
  st: 'steering_options',
  dr: 'driver_type_options',
  do: 'any_door_options',
  tmns: 'transmission_options',
  bsty: 'body_style_options',
  fues: 'fuel_type_options',
  ecls: 'color_options'
}

export async function fill_search_form_data(callback, url = null) {
  const dataUrl = url || '/ajax_v2/init_search_form_data'

  await $.ajax({
    url: dataUrl,
    type: 'GET',
    success: function (response) {
      callback?.(response)
    }
  })
}

export const pull_maker_model_select_option = (maker_options, model_options) => {
  let htmlOptions = ''
  const makeSelectObj  = document.querySelector('select[name="make"]')
  const modelSelectObj = document.querySelector('select[name="model"]')
  makeSelectObj.innerHTML = ''
  maker_options.forEach(option => htmlOptions += `<option ${assignValueToOptions(option[1])}>${option[0]}</option>`)
  makeSelectObj.innerHTML = htmlOptions
  modelSelectObj.dataset.model = model_options
}

const assignValueToOptions = (value) => value === '' ? `value="${value}" disabled` : `value="${value}"`

export const pull_select_option = (data, filedLists, checkHomePage = false) => {
  filedLists.forEach(field => {
    const selectEl = document.querySelector(`select[name="${field}"]`)
    selectEl.innerHTML = ''
    let htmlOptions = ''
    const options = data[FIELDMAPPING[field]]
    options.forEach(option => htmlOptions += `<option value="${option[1]}">${option[0]}</option>`)
    selectEl.innerHTML = htmlOptions

    if (checkHomePage) {
      selectEl.addEventListener('change', function(e) {
        $(`#ett_select_${field}`).val(e.target.value)
      })

      const selectedValue = $(`#ett_select_${field}`).val()
      selectEl.value = selectedValue == 'undefined' ? '0' : selectedValue
    }
  })
}

export const renderRadioBtnOnSp = (data) => {
  const radioTargetMapping = [
    [data.steering_options, 'steering', 'st', 'cbSteeringField'],
    [data.driver_type_options, 'drive_type', 'dr', 'cbDriveTypeField']
  ]

  radioTargetMapping.forEach(target => {
    let html = ''
    target[0].forEach(data => {
      html += `
      <input id="${target[2]}_${data[1]}" data-advanced-search-target="${target[3]}" type="radio" value="${data[1]}" ${defaultChecked(data[1])} name="${target[2]}">
      <label class="radio__sp-lb-wrap" for="${target[2]}_${data[1]}">${data[0]}</label>
      `
    })
    document.querySelector(`.block-label_list.${target[1]}`).innerHTML = html
    html = ''
  })
}

export const fillDataIntoModalOnSp = (data) => {
  const targetMapping = [
    [data.maker_options, 'make', 'make', 'click->advanced-search#setDataMake' ],
    [data.body_style_options, 'bodystyle', 'bsty', 'click->advanced-search#setDataBodytype' ],
    [data.regis_year_from, 'year_from', 'fid', 'click->advanced-search#setDataYearFrom' ],
    [data.regis_year_to, 'year_to', 'jid', 'click->advanced-search#setDataYearTo' ],
    [data.regis_month_from, 'month_from', 'smo', 'click->advanced-search#setDataMonthFrom' ],
    [data.regis_month_to, 'month_to', 'emo', 'click->advanced-search#setDataMonthTo' ],
    [data.fob_options_min, 'fob_from', 'prcf', 'click->advanced-search#setDataFobFrom' ],
    [data.fob_options_max, 'fob_to', 'prct', 'click->advanced-search#setDataFobTo' ],
    [data.mileage_options_from, 'mileage_from', 'mimn', 'click->advanced-search#setDataMileageFrom' ],
    [data.mileage_options_to, 'mileage_to', 'mimx', 'click->advanced-search#setDataMileageTo' ],
    [data.engine_options_from, 'engine_capacity_from', 'sds', 'click->advanced-search#setDataEngineFrom' ],
    [data.engine_options_to, 'engine_capacity_to', 'eds', 'click->advanced-search#setDataEngineTo' ],
    [data.transmission_options, 'transmission', 'tmns', 'click->advanced-search#setDataTransmission' ],
    [data.accident_options, 'accident', 'ac', 'click->advanced-search#setDataAccidentCar' ],
    [data.fuel_type_options, 'fuel_type', 'fues', 'click->advanced-search#setDataFuelType' ],
    [data.color_options, 'color', 'ecls', 'click->advanced-search#setDataColor' ],
    [data.any_door_options, 'door', 'do', 'click->advanced-search#setDataDoor' ]
  ]
  targetMapping.forEach(target => {
    const wrapper = document.querySelector(`.extension-select__box.${target[1]}`)
    let htmlOptions = ''
    target[0].forEach(obj => {
      if(target[1] === 'make' && obj.length > 2) {
        htmlOptions += `
          <li class="extension-select__box__list_children disabled"><label data-action="${target[3]}">${obj[0]}</label></li>
        `
      } else {
        htmlOptions += `
          <li class="extension-select__box__list_children">
            <input id="${target[2]}_${obj[1]}" type="radio" value="${obj[1]}" ${defaultChecked(obj[1])} name="_${target[2]}">
            <label data-action="${target[3]}" for="${target[2]}_${obj[1]}">${obj[0]}</label>
          </li>
        `
      }
    })
    let html = `
      <span class="extension-select__box__btn_close" data-action="click->advanced-search#removeExtensionSelectBox"></span>
      <ul class="extension-select__box__list_parents year_from">
        ${htmlOptions}
      </ul>
    `
    wrapper.innerHTML = html
    html = ''
  })
}

export const optionValueValid = (target, value) => {
  const options = target.options

  return Array.from(options).map(e => e.value).includes(value)
}

const defaultChecked = (value, checkValue = 0) => value === checkValue ? `checked="checked"` : ''
