import Cookies from 'js-cookie';

export default function bindActionSetCookiesViewport() {
  const cookies_name_for_sp = 'TradeSmart'
  const cookies_name_for_pc = 'TradePC'
  const spBtn = document.querySelector('.footviewpc')
  const pcBtn = document.querySelector('.footviewsp')

  setAction(spBtn, cookies_name_for_pc, cookies_name_for_sp)
  setAction(pcBtn, cookies_name_for_sp, cookies_name_for_pc)
}

const setAction = (target, oldCookies, newCookies) => {
  if (target) {
    target.addEventListener('click', () => {
      Cookies.remove(oldCookies)
      Cookies.set(newCookies, 'off', { expires: 30 })
    })
  }
}
