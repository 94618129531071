import { ajax } from '@rails/ujs'

export default function fetch_frame(elTarget, url) {
  ajax({
    url: url,
    type: 'GET',
    success: function (response) {
      if (response === '') return;
      elTarget.outerHTML = response.body.innerHTML
    }
  })
}
