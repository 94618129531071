import {ajax} from '@rails/ujs'
import { Controller } from 'stimulus'
import { update_model_select_when_change_maker, update_model_select,
         fill_search_form_data, pull_maker_model_select_option, pull_select_option, optionValueValid } from '../shared/data_loader'
import { getQueryParamsFromURL } from '../shared/param_url'
import passAjaxInvalidAuthenticityToken from '../shared/authenticity_hanlder'
import { getDataURLSearchParams } from '../shared/url_search_param'

const numberOfResults = document.querySelector('span.number-of-results')
const numberOfResultOnHeader = document.querySelector('.car__counter--header')
const NUMBER_REGEX = /^[+-]?\d+$/
export default class extends Controller {
  static targets = ['advancedSearchArea', 'modalShade', 'conditionBody',
                    'ipKeywordField' , 'slFobMinField' ,'slFobMaxField',
                    'cbRegularCarField' ,'slMakeField' ,'slModelField' ,
                    'slRegistrationFromField','slRegistrationToField' ,'slRegistrationMonthFromField',
                    'slRegistrationMonthToField', 'slMileageFromField', 'slMileageToField',
                    'slCapacityFromField', 'slCapacityToField' ,'cbTransmissionField',
                    'slAccidentField', 'slSteeringField', 'cbConditionField',
                    'cbSpecialPriceField', 'cbFueltypeField', 'cbBodytypeField', 'hiddenUIdField', 'hiddenCoCodeField',
                    'slSubBodytypeField', 'slDriveTypeField', 'cbColorField', 'slDoorField', 'searchFormPc'
                  ]

  connect() {
    document.body.addEventListener('click', () => this.hideCurrentTooltip())

    fill_search_form_data((res) => {
      const filedLists = ['fid', 'jid', 'prcf', 'prct', 'sds', 'eds', 'mimn', 'mimx', 'smo', 'emo', 'ac', 'st', 'dr', 'do']
      const targetMapping = [
        ['tmns', 'section.transmission', 'transmission', 'cbTransmissionField', 'transmission_options'],
        ['fues', 'section.fueltypes', 'fueltypes', 'cbFueltypeField', 'fuel_type_options'],
        ['ecls', 'section.excolors', 'excolors', 'cbColorField', 'color_options'],
        ['bsty', 'section.bodystyles', 'bodystyles', 'cbBodytypeField', 'body_style_options']
      ]
      pull_maker_model_select_option(res.maker_options, res.model_options)
      update_model_select()
      update_model_select_when_change_maker(false, (e) => searchParams.model = { value: 0, text: undefined })
      this.drawCheckboxOptions(res, targetMapping)
      pull_select_option(res, filedLists)
      this.keywordFieldHandler()
      this.setRegistrationYear()
      this.selectBoxHandler()
      this.checkBoxInSearchFormHandler()
      this.setCheckBoxFollowBySubBodustyle()
      this.setValuesFromQueryParams(true)
      this.showConditionBody()
      this.hiddenUIdFieldTarget.value = searchParams.userId !== undefined ? searchParams.userId : ''
      this.hiddenCoCodeFieldTarget.value = searchParams.coParam !== undefined ? searchParams.coParam : ''
      this.showReset()
    })
  }

  drawCheckboxOptions(data, targetMapping) {
    let template= ''
    targetMapping.forEach(item => {
      data[item[4]].forEach(value => {
        template += `
          <li class="vehicle-search-modal__item custom-cb">
            <input type="checkbox" name="${item[0]}[]" id="${item[2]}_${value[1]}" value="${value[1]}" data-extend-search-target="${item[3]}" autocomplete="off">
            <label class="vehicle-search-modal-checkbox__wrap" for="${item[2]}_${value[1]}">${value[0]}</label>
            ${this.renderOptionSubBsty(item[0], value[1], data.secondary_body_style_options)}
          </li>
        `
      })
      document.querySelector(`${item[1]} .vehicle-search-modal__block`).innerHTML = template
      template = ''
    })
  }

  renderOptionSubBsty(inputName, primaryBstyId, subBstyOptions) {
    if (inputName === 'bsty' && [4,8].includes(primaryBstyId)) {
      return `
        <span class="vehicle-select__wrap bodystyle-truck">
          <select name="bsty[]" id="sl_bsty_${primaryBstyId}" data-extend-search-target="slSubBodytypeField">
            ${this.secondaryBodyStyleOptions(subBstyOptions, primaryBstyId)}
          </select>
          <span class="note">*please select body style2</span>
        </span>
      `
    } else {
      return ''
    }
  }

  secondaryBodyStyleOptions(secondary_body_style_options, primary_id) {
    let subBodyStyleOptionsArr = []
    let subBodyStyleOptionsText = ''
    subBodyStyleOptionsArr = secondary_body_style_options.filter(option => option[2] == primary_id )
    const subBodyStyleOptions = subBodyStyleOptionsArr.map(option => [option[0], `${primary_id}.${option[1]}`])
    subBodyStyleOptions.forEach(option => subBodyStyleOptionsText += `<option value="${option[1]}">${option[0]}</option>` )
    return '<option value="0">Any</option>' + subBodyStyleOptionsText
  }

  toggleAdvancedSearch(e) {
    const parent = e.currentTarget.parentElement
    let target = this.advancedSearchAreaTarget
    if (target.offsetHeight === 0) {
      target.style.height = '132px'
      parent.classList.add('extend')
      setTimeout(() => target.style.overflow = 'inherit', 300);
    } else {
      parent.classList.remove('extend')
      target.style.height = 0
      target.style.overflow = 'hidden'
    }
  }

  openTooltip(e) {
    e.stopPropagation();
    const currentTarget = e.currentTarget
    const newtooltip = currentTarget.querySelector('.tooltip--block')
    const btnClose = newtooltip.querySelector('button.icon-close')
    this.hideCurrentTooltip()

    newtooltip.classList.add('appearance')
    btnClose.setAttribute('data-action', 'click->extend-search#closeTooltip')
  }

  closeTooltip(e) {
    e.stopPropagation();
    e.currentTarget.closest('.tooltip--block.appearance').classList.remove('appearance')
  }

  hideCurrentTooltip() {
    const currentTooltip = document.querySelector('.tooltip--block.appearance')
    if (currentTooltip) { currentTooltip.classList.remove('appearance') }
  }

  openModal(e) {
    const currentTarget = e.currentTarget;
    const modal = currentTarget.nextSibling
    const shade = currentTarget.previousSibling

    shade.classList.add('appearance')
    modal.classList.add('appearance')
  }

  closeModal() {
    document.querySelector('.modal__block.appearance').classList.remove('appearance')
    document.querySelector('.modal__shade.appearance').classList.remove('appearance')
  }

  setRegistrationYear() {
    this.toggleRegistrationMonth(this.slRegistrationFromFieldTarget, this.slRegistrationMonthFromFieldTarget, 'registrationMonthFrom')
    this.toggleRegistrationMonth(this.slRegistrationToFieldTarget, this.slRegistrationMonthToFieldTarget, 'registrationMonthTo')
  }

  toggleRegistrationMonth(target, affectedComponents, key) {
    target.addEventListener('change', (e) => {
      if(e.target.value === '0') {
        affectedComponents.disabled = true
        affectedComponents.selectedIndex = 0;
        searchParams[key] = { value: 0, text: undefined }
      } else {
        affectedComponents.disabled = false
      }
    })
  }

  closeTransmissionModal() {
    this.validateBeforeClose(searchParams.transmissions, this.cbTransmissionFieldTargets)
  }

  commitValueTransmissionModal() {
    this.commitValueBeforeClose('transmissions', this.cbTransmissionFieldTargets)
  }

  closeFueltypesModal() {
    this.validateBeforeClose(searchParams.fueltypes, this.cbFueltypeFieldTargets)
  }

  commitValueFueltypesModal() {
    this.commitValueBeforeClose('fueltypes', this.cbFueltypeFieldTargets)
  }

  closeBodystyleModal() {
    this.validateBeforeClose(searchParams.bodytypes, this.cbBodytypeFieldTargets)
  }

  commitValueBodystyleModal() {
    let subBodytypes = []
    this.commitValueBeforeClose('bodytypes', this.cbBodytypeFieldTargets)
    this.slSubBodytypeFieldTargets.forEach(el => {
      const wrapper = el.closest('.vehicle-search-modal__item')
      const checkbox = wrapper.querySelector('input[type="checkbox"]')
      const label = wrapper.querySelector('label.vehicle-search-modal-checkbox__wrap')
      if (el.options[el.selectedIndex].value === '') {
        searchParams.subBodytypes = []
      } else {
        if (checkbox.checked) {
          subBodytypes.push({ mainBt: label.innerText,
                              value: el.options[el.selectedIndex].value,
                              text: el.options[el.selectedIndex].innerText })
        }
      }
      searchParams.subBodytypes = subBodytypes
      this.showConditionBody()
    })
  }

  setCheckBoxFollowBySubBodustyle() {
    this.slSubBodytypeFieldTargets.forEach(el => {
      el.addEventListener('change', () => {
        const input = el.closest('.vehicle-search-modal__item').querySelector('input[type="checkbox"]')
        if (el.options[el.selectedIndex].value !== '') input.checked = true
      })
    })
  }

  closeColorModal() {
    this.validateBeforeClose(searchParams.colors, this.cbColorFieldTargets)
  }

  commitValueColorModal() {
    this.commitValueBeforeClose('colors', this.cbColorFieldTargets)
  }

  validateBeforeClose(params, targetLists) {
    if (params.length === 0) { targetLists.forEach(element => element.checked = false) }
    this.closeModal()
  }

  commitValueBeforeClose(key, targetLists) {
    let checkedList = []
    targetLists.forEach(element => {
      if(element.checked) {
        checkedList.push(
          {
            value: element.value,
            text: element.nextElementSibling.textContent
          }
        )
      }
    });
    searchParams[key] = checkedList
    this.showConditionBody()
    this.closeModal()
  }

  keywordFieldHandler() {
    this.ipKeywordFieldTarget.addEventListener('change', (e) => this.keywordFieldProcesser(e) )
  }

  keywordFieldProcesser(e) {
    searchParams.keyword = e.target.value
    this.showConditionBody()
    this.ajaxCarCounter()
  }

  selectBoxHandler() {
    const targets = [
      { target: this.slFobMinFieldTarget, key: 'fobMin' },
      { target: this.slFobMaxFieldTarget, key: 'fobMax' },
      { target: this.slMakeFieldTarget, key: 'make' },
      { target: this.slModelFieldTarget, key: 'model' },
      { target: this.slRegistrationFromFieldTarget, key: 'registrationYearFrom' },
      { target: this.slRegistrationToFieldTarget, key: 'registrationYearTo' },
      { target: this.slRegistrationMonthFromFieldTarget, key: 'registrationMonthFrom' },
      { target: this.slRegistrationMonthToFieldTarget, key: 'registrationMonthTo' },
      { target: this.slMileageFromFieldTarget, key: 'mileageFrom' },
      { target: this.slMileageToFieldTarget, key: 'mileageTo' },
      { target: this.slCapacityFromFieldTarget, key: 'capacityFrom' },
      { target: this.slCapacityToFieldTarget, key: 'capacityTo' },
      { target: this.slAccidentFieldTarget, key: 'accident' },
      { target: this.slSteeringFieldTarget, key: 'steering' },
      { target: this.slDriveTypeFieldTarget, key: 'driveType' },
      { target: this.slDoorFieldTarget, key: 'door' }
    ]
    targets.forEach(item => {
      item.target.addEventListener('change', (el) => {

        if (item.target.value === '') {
          searchParams[item.key] = { value: 0, text: undefined }
        } else {
          let selectedText = item.target.options[el.target.selectedIndex].textContent
          searchParams[item.key] = { value: item.target.value, text: selectedText }
        }
        this.ajaxCarCounter()
        this.showConditionBody()
      })
    })
  }

  checkBoxInSearchFormHandler() {
    const targets = [
      {
        target: this.cbConditionFieldTarget,
        key: 'conditionNewCar'
      },
      {
        target: this.cbSpecialPriceFieldTarget,
        key: 'specialPrice'
      },
      {
        target: this.hasCbRegularCarFieldTarget ? this.cbRegularCarFieldTarget : false,
        key: 'regularCar'
      }
    ]

    targets.forEach(item => {
      if (item.target === false) { return; }
      item.target.addEventListener('change', (el) => {
        if (el.target.checked) {
          if (item.key === 'regularCar') { searchParams[item.key]['text'] = el.target.value }
          searchParams[item.key]['value'] = 1
        } else {
          if (item.key === 'regularCar') { searchParams[item.key]['text'] = undefined }
          searchParams[item.key]['value'] = 0
        }
        this.showConditionBody()
        this.ajaxCarCounter()
      })
    })
  }

  showConditionBody() {
    const textBodyWraperOnHeader = document.querySelector('.vehicle__search__condition__block')
    const textBodyWraper = this.conditionBodyTarget
    let body = searchParams.textParsed();
    if(body.replace('/\s/g', '') === '') {
      textBodyWraper.classList.add('d-none')
      textBodyWraperOnHeader.classList.add('d-none')
    } else {
      textBodyWraper.classList.remove('d-none')
      textBodyWraper.innerText = body
      textBodyWraperOnHeader.classList.remove('d-none')
      textBodyWraperOnHeader.innerText = body
    }
  }

  resetForm() {
    const selectTargets = [
      this.slFobMinFieldTarget,
      this.slFobMaxFieldTarget,
      this.slMakeFieldTarget,
      this.slModelFieldTarget,
      this.slRegistrationFromFieldTarget,
      this.slRegistrationToFieldTarget,
      this.slRegistrationMonthFromFieldTarget,
      this.slRegistrationMonthToFieldTarget,
      this.slMileageFromFieldTarget,
      this.slMileageToFieldTarget,
      this.slCapacityFromFieldTarget,
      this.slCapacityToFieldTarget,
      this.slAccidentFieldTarget,
      this.slSteeringFieldTarget,
      this.slDriveTypeFieldTarget,
      this.slDoorFieldTarget,
    ]
    selectTargets.forEach(el => el.selectedIndex = 0)
    this.slModelFieldTarget.options.length = 1

    if (this.hasCbRegularCarFieldTarget) { this.cbRegularCarFieldTarget.checked = false }

    const checkBoxTargets = [
      this.cbConditionFieldTarget,
      this.cbSpecialPriceFieldTarget
    ]
    checkBoxTargets.forEach(el => el.checked = false)

    const checkBoxModalTargets = [
      this.cbTransmissionFieldTargets,
      this.cbFueltypeFieldTargets,
      this.cbBodytypeFieldTargets,
      this.cbColorFieldTargets
    ]
    checkBoxModalTargets.forEach(item => item.forEach(el => el.checked = false))
    this.slSubBodytypeFieldTargets.forEach(el => el.selectedIndex = 0 )
    this.ipKeywordFieldTarget.value = ''
    this.hiddenUIdFieldTarget.value = ''
    this.hiddenCoCodeFieldTarget.value = ''

    searchParams.reset()
    this.ajaxCarCounter()
    this.showConditionBody()
  }

  setValuesFromQueryParams(init = false) {
    const targets = {
      fd: { target: this.ipKeywordFieldTarget, key: 'keyword', type: 'text' },
      prcf: { target: this.slFobMinFieldTarget, key: 'fobMin', type: 'select' },
      prct: { target: this.slFobMaxFieldTarget, key: 'fobMax', type: 'select' },
      make: { target: this.slMakeFieldTarget, key: 'make', type: 'textSelect' },
      model: { target: this.slModelFieldTarget, key: 'model', type: 'textSelect' },
      fid: { target: this.slRegistrationFromFieldTarget, key: 'registrationYearFrom', type: 'select' },
      jid: { target: this.slRegistrationToFieldTarget, key: 'registrationYearTo', type: 'select' },
      smo: { target: this.slRegistrationMonthFromFieldTarget, key: 'registrationMonthFrom', type: 'select' },
      emo: { target: this.slRegistrationMonthToFieldTarget, key: 'registrationMonthTo', type: 'select' },
      mimn: { target: this.slMileageFromFieldTarget, key: 'mileageFrom', type: 'select' },
      mimx: { target: this.slMileageToFieldTarget, key: 'mileageTo', type: 'select' },
      sds: { target: this.slCapacityFromFieldTarget, key: 'capacityFrom', type: 'select' },
      eds: { target: this.slCapacityToFieldTarget, key: 'capacityTo', type: 'select' },
      ac: { target: this.slAccidentFieldTarget, key: 'accident', type: 'select' },
      st: { target: this.slSteeringFieldTarget, key: 'steering', type: 'select' },
      dr: { target: this.slDriveTypeFieldTarget, key: 'driveType', type: 'select' },
      do: { target: this.slDoorFieldTarget, key: 'door', type: 'select' },
      rfc: { target: this.hasCbRegularCarFieldTarget ? this.cbRegularCarFieldTarget : false, key: 'regularCar', type: 'checkbox' },
      nw: { target: this.cbConditionFieldTarget, key: 'conditionNewCar', type: 'checkbox' },
      spp: { target: this.cbSpecialPriceFieldTarget, key: 'specialPrice', type: 'checkbox' },
      tmns: { targets: this.cbTransmissionFieldTargets, key: 'transmissions', type: 'multiplecheckbox' },
      fues: { targets: this.cbFueltypeFieldTargets, key: 'fueltypes', type: 'multiplecheckbox' },
      ecls: { targets: this.cbColorFieldTargets, key: 'colors', type: 'multiplecheckbox' },
      bsty: { targets: this.cbBodytypeFieldTargets, key: 'bodytypes', type: 'multiplecheckboxwithsubtarget',
        subTargets: this.slSubBodytypeFieldTargets, subKey: 'subBodytypes' }
    }

    const valuesParams = getQueryParamsFromURL()

    if (valuesParams.hasOwnProperty('make')) {
      try {
        const value = decodeURIComponent(valuesParams['make']).toLowerCase()

        if (optionValueValid(this.slMakeFieldTarget, value)) {
          this.slMakeFieldTarget.value = value
          update_model_select()
        }
      } catch {}
    }

    if (valuesParams.hasOwnProperty('fid')) { this.slRegistrationMonthFromFieldTarget.disabled = false }
    if (valuesParams.hasOwnProperty('jid')) { this.slRegistrationMonthToFieldTarget.disabled = false }

    for (var paramsKey in valuesParams) {
      if (targets[paramsKey] === undefined) continue;

      switch(targets[paramsKey].type) {
        case 'text':
          targets[paramsKey].target.value = valuesParams[paramsKey]
          searchParams[targets[paramsKey].key] = valuesParams[paramsKey]
          break
        case 'select':
          this.selectFillHandler(targets[paramsKey], decodeURI(valuesParams[paramsKey]))
          break
        case 'checkbox':
          this.checkboxFillHandler(targets[paramsKey], valuesParams[paramsKey])
          break
        case 'multiplecheckbox':
          this.multipleCheckboxFillHandler(targets[paramsKey], valuesParams[paramsKey])
          break
        case 'multiplecheckboxwithsubtarget':
          if (Array.isArray(valuesParams[paramsKey])) {
            let decimalValues = valuesParams[paramsKey].filter(val => val.includes('.'))
            let integerValues = valuesParams[paramsKey].filter(val => !val.includes('.'))
                                  .concat(decimalValues.map(val => parseInt(val).toString()))

            this.multipleCheckboxFillHandler(targets[paramsKey], integerValues)
            this.subSelectFillHandler(targets[paramsKey], decimalValues)
          } else {
            this.multipleCheckboxFillHandler(targets[paramsKey], valuesParams[paramsKey])
          }
          break
        case 'textSelect':
          try {
            this.textSelectHandler(targets[paramsKey], decodeURIComponent(valuesParams[paramsKey]))
          } catch {}
          break
      }
    }

    if (!init) { this.ajaxCarCounter() }
  }

  selectFillHandler(element, value) {
    if (!value.trim().match(NUMBER_REGEX)) {
      return
    }

    element.target.value = parseInt(value)

    searchParams[element.key] = {
      value: value,
      text: element.target.selectedIndex === -1 ? '' : element.target.options[element.target.selectedIndex].textContent
    }
  }

  checkboxFillHandler(element, value) {
    if (element.key === 'regularCar') {
      searchParams[element.key]['text'] = element.target.value
    }

    if (value.trim().match(NUMBER_REGEX)) {
      value = parseInt(value)
    }

    if (value === 1) { searchParams[element.key]['value'] = 1 }
    element.target.checked = value === 1
  }

  multipleCheckboxFillHandler(element, values) {
    let checkedList = []

    element.targets.forEach(target => {
      if (Array.isArray(values) && values.includes(target.value) || values === parseInt(target.value)) {
        target.checked = true
        checkedList.push({
          value: target.value,
          text: target.nextElementSibling.textContent
        })
      }
    })

    searchParams[element.key] = checkedList
  }

  subSelectFillHandler(element, values) {
    let checkedList = []

    element.subTargets.forEach(target => {
      let optionValues = []

      for (let opt of target.options) {
        optionValues.push(opt.value)
      }

      let matchValue = optionValues.find(value => values.includes(value))

      if (matchValue) {
        target.value = matchValue
        checkedList.push({
          mainBt: target.closest('.vehicle-search-modal__item').querySelector('label.vehicle-search-modal-checkbox__wrap').innerText,
          value: target.options[target.selectedIndex].value,
          text: target.options[target.selectedIndex].innerText
        })
      }
    })

    searchParams[element.subKey] = checkedList
  }

  ajaxCarCounter() {
    let data = { search_params: searchParams.makeParams() }

    ajax({
      url: '/ajax_v2/advancedsearch/search_counter',
      type: 'POST',
      dataType: 'json',
      data: getDataURLSearchParams(data),
      success: function (response) {
        numberOfResults.innerText = response.count
        numberOfResultOnHeader.innerText = `${response.count} results match`
      },
      error: function(response) { passAjaxInvalidAuthenticityToken(response) }
    })
  }

  showReset() {
    if (this.ipKeywordFieldTarget.value) {
      $('.clear-icon').css('position', 'absolute')
      $('.clear-icon').show()
    } else {
      $('.clear-icon').hide()
    }
  }

  clearTextField(event) {
    event.preventDefault()
    $('.key-word input').val('')
    $('.clear-icon').hide()
    this.keywordFieldProcesser(event)
  }

  textSelectHandler(element, value) {
    value = value.toLowerCase()

    if (optionValueValid(element.target, value)) {
      element.target.value = value

      searchParams[element.key] = {
        value: value,
        text: element.target.selectedIndex === -1 ? '' : element.target.options[element.target.selectedIndex].textContent
      }
    }
  }
}
