import { Controller } from 'stimulus'
import { showSectionWhenScolling } from '../shared/landing_page'
import { ajax } from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'welcomeSection',
    'getRewardsSection',
    'videoSection',
    'whatIsTcvSection',
    'howToGetRewardsSection',
    'faqSection',
    'modalSignup',
    'modalSignin',
    'fixedFooter'
  ]

  connect() {
    window.addEventListener('scroll', this.showFixedFooterWhenScrolling.bind(this))
  }

  showFixedFooterWhenScrolling() {
    const headerHeight = document.querySelector('.header-area').clientHeight +
                         document.querySelector('.gnav__area').clientHeight

    const targetsAboveSection = ['welcomeSection', 'getRewardsSection', 'videoSection', 'whatIsTcvSection', 'howToGetRewardsSection', 'faqSection']

    showSectionWhenScolling(headerHeight, targetsAboveSection, this)
  }
}
