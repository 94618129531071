export default function SlideImage() {
  const thumbItemClass         = '.slide__gallery-thumbItem--js'
  const thumbItemClassIsActive = '.slide__gallery-thumbItem--js.selected'
  
  const wrapper       = document.querySelector('.slide__gallery--js')
  const thumbListItem = wrapper.querySelector('.slide__gallery-Listitem--js')
  const thumbItems    = wrapper.querySelectorAll(thumbItemClass)
  const mainPictute   = wrapper.querySelector('.slide__gallery-mainPic--js')
  const btnPrev       = wrapper.querySelector('.slide__gallery-prev--js')
  const btnNext       = wrapper.querySelector('.slide__gallery-next--js')

  let maxItems     = thumbListItem.childElementCount
  let currentIndex = 1

  document.addEventListener('DOMContentLoaded', () => thumbItems[0].classList.add('selected') );

  thumbItems.forEach(item => {
    item.addEventListener('click', () => {
      setImage(item, getMainSrc(item))
    })
  })

  btnPrev.addEventListener('click', () => {
    if (currentIndex == 1) {
      const lastItem = wrapper.querySelector(`${thumbItemClass}[data-thumb-no="${maxItems}"]`)
      setImage(lastItem, getMainSrc(lastItem))
    } else {
      const preItem = wrapper.querySelector(`${thumbItemClass}[data-thumb-no="${currentIndex - 1}"]`)
      setImage(preItem, getMainSrc(preItem))
    }
  })

  btnNext.addEventListener('click', () => {
    if (currentIndex == maxItems) {
      const firstItem = wrapper.querySelector(`${thumbItemClass}[data-thumb-no="1"]`)
      setImage(firstItem, getMainSrc(firstItem))
    } else {
      const preItem = wrapper.querySelector(`${thumbItemClass}[data-thumb-no="${currentIndex + 1}"]`)
      setImage(preItem, getMainSrc(preItem))
    }
  })

  const setImage = (currentItem, dataMainSrc) => {
    wrapper.querySelector(thumbItemClassIsActive).classList.remove('selected')
    mainPictute.setAttribute('src', dataMainSrc)
    mainPictute.setAttribute('data-src', dataMainSrc)
    currentItem.classList.add('selected')
    currentIndex = parseInt(currentItem.dataset.thumbNo)
  }

  const getMainSrc = (parentItem) => {
    return parentItem.children[0].dataset.mainSrc
  }

  async function preloadImages() {
    thumbItems.forEach(item => {
      let image = new Image();
      image.src = item.children[0].dataset.mainSrc;
    });
  }
  preloadImages();
}
