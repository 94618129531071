import { ajax } from '@rails/ujs'

export function fetch_amount_notify(callback) {
  setTimeout(() => {    
    ajax({
      url: '/ajax_v2/messages/notify_counter',
      type: 'GET',
      success: (response) => callback(response['unread_msg']),
      error: () => callback(0)
    })
  }, 200);
}
