import Cookies from 'js-cookie'

const ERROR_OFFER_KEY = 'erroroffer'

export function setOfferErrorMessage(data) {
  const { is_success, response } = data

  if (is_success) {
    Cookies.set('not_show_popup_assist', 'true', { expires: 7 })
    return
  }

  const { errorCode, errorMessage } = response
  localStorage.setItem(ERROR_OFFER_KEY, JSON.stringify({ errorCode, errorMessage }))
}

export function showOfferErrorMessage() {
  const rawData = localStorage.getItem(ERROR_OFFER_KEY)
  const target = document.querySelector('.error-message')

  if (!rawData || !target) return

  const errorData = JSON.parse(rawData)

  if (target.dataset.error == errorData.errorCode) {
    target.innerHTML = errorData.errorMessage
  }
}
