export default class SearchParams {
  constructor(params) {
    this.keyword    = params?.keyword
    this.fobMin     = { value: params.fobMin?.value || 0, text: params.fobMin?.text }
    this.fobMax     = { value: params.fobMax?.value || 0, text: params.fobMax?.text }
    this.regularCar = { value: params.regularCar?.value || 0, text: params.regularCar?.text }
    this.make       = { value: params.make?.value  || '', text: params.make?.text }
    this.model      = { value: params.model?.value || '', text: params.model?.text }
    this.registrationYearFrom  = { value: params.registrationYearFrom?.value  || 0, text: params.registrationYearFrom?.text || '' }
    this.registrationYearTo    = { value: params.registrationYearTo?.value    || 0, text: params.registrationYearTo?.text || '' }
    this.registrationMonthFrom = { value: params.registrationMonthFrom?.value || 0, text: params.registrationMonthFrom?.text }
    this.registrationMonthTo   = { value: params.registrationMonthTo?.value   || 0, text: params.registrationMonthTo?.text }
    this.mileageFrom   = { value: params.mileageFrom?.value  || 0, text: params.mileageFrom?.text || '' }
    this.mileageTo     = { value: params.mileageTo?.value  || 0, text: params.mileageTo?.text }
    this.capacityFrom  = { value: params.capacityFrom?.value  || 0, text: params.capacityFrom?.text }
    this.capacityTo    = { value: params.capacityTo?.value  || 0, text: params.capacityTo?.text }
    this.transmissions = params?.transmissions|| [] // [{value: id, text: transmission_name}, {value: id, text: transmission_name}]
    this.accident      = { value: params.accident?.value  || 0, text: params.accident?.text }
    this.steering      = { value: params.steering?.value  || 0, text: params.steering?.text }
    this.conditionNewCar = { value: params?.conditionNewCar || 0, text: 'New Cars Only' }
    this.specialPrice    = { value: params?.specialPrice || 0, text: 'Special Price Only' }
    this.fueltypes       = params?.fueltypes    || [] // [{value: id, text: fueltype_name}, {value: id, text: fueltype_name}]
    this.bodytypes       = params?.bodytypes    || [] // [{value: id, text: bodytype_name}, {value: id, text: bodytype_name}]
    this.subBodytypes    = params?.subBodytypes || [] // { mainBt: params.subBodytype?.mainBt, value: params.subBodytype?.value, text: params.subBodytype?.id}
    this.driveType       = { value: params.driveType?.value  || 0, text: params.driveType?.text }
    this.colors          = params?.colors  || [] // [{value: id, text: color_name}, {value: id, text: color_name}]
    this.door            = { value: params.door?.value  || 0, text: params.door?.text || '' }
    this.userId          = getParamOnUrl('uid')
    this.coParam         = getParamOnUrl('co')

    this.reset = () => {
      this.accident              = { value: 0, text: undefined },
      this.bodytypes             = [],
      this.capacityFrom          = { value: 0, text: undefined },
      this.capacityTo            = { value: 0, text: undefined },
      this.colors                = [],
      this.conditionNewCar       = { value: 0, text: 'New Cars Only'},
      this.door                  = { value: 0, text: '' },
      this.driveType             = { value: 0, text: undefined },
      this.fobMax                = { value: 0, text: undefined },
      this.fobMin                = { value: 0, text: undefined },
      this.fueltypes             = [],
      this.keyword               = undefined,
      this.make                  = { value: '', text: undefined },
      this.mileageFrom           = { value: 0, text: '' },
      this.mileageTo             = { value: 0, text: undefined },
      this.model                 = { value: '', text: undefined },
      this.registrationMonthFrom = { value: 0, text: undefined },
      this.registrationMonthTo   = { value: 0, text: undefined },
      this.registrationYearFrom  = { value: 0, text: '' },
      this.registrationYearTo    = { value: 0, text: '' },
      this.regularCar            = { value: 0, text: undefined },
      this.specialPrice          = { value: 0, text: 'Special Price Only'},
      this.steering              = { value: 0, text: undefined },
      this.subBodytypes          = [],
      this.transmissions         = [],
      this.userId                = undefined
      this.coParam               = undefined
    }

    this.makeParams = () => {
      let params = {
        fd:    this.keyword,
        prcf:  this.fobMin.value,
        prct:  this.fobMax.value,
        rfc:   this.regularCar.value,
        make:  this.make.value,
        model: this.model.value,
        fid:   this.registrationYearFrom.value,
        jid:   this.registrationYearTo.value,
        smo:   this.registrationMonthFrom.value,
        emo:   this.registrationMonthTo.value,
        mimn:  this.mileageFrom.value,
        mimx:  this.mileageTo.value,
        sds:   this.capacityFrom.value,
        eds:   this.capacityTo.value,
        tmns:  this.transmissions.map(item => item.value),
        ac:    this.accident.value,
        st:    this.steering.value,
        nw:    this.conditionNewCar.value,
        spp:   this.specialPrice.value,
        fues:  this.fueltypes.map(item => item.value),
        bsty:  this.bodytypes.map(item => item.value).concat(this.subBodytypes.map(item => item.value)).filter(e => e !== '0'),
        dr:    this.driveType.value,
        ecls:  this.colors.map(item => item.value),
        do:    this.door.value,
        uid:   this.userId,
        co:    this.coParam
      }

      let new_params = {}
      for (var key in params){
        if (params[key] === 0 || params[key]?.length === 0 || params[key] == undefined) continue;
        new_params[key] = params[key]
      }

      return JSON.stringify(new_params)
    }

    this.textParsed = () => {
      return [this.makeText(), this.modelText(), this.registrationText(),
              this.mileageText(), this.fobText(), this.capacityText(), this.transmissionText(),
              this.accidentText(), this.steeringText(), this.conditionNewCarText(),
              this.regularCarText(), this.specialPriceText(), this.fueltypeText(), this.bodytypeText(),
              this.driveTypeText(), this.colorsText(), this.doorText(), this.keyword ?? ''
              ].filter(n => n).join(', ').replace(/,\s *$/, '')
    }

    this.makeText = () => {
      return this.make.value == 0 ? '' : `${this.make.text.replace(/\s\(\d+\)/g, '')}`
    }

    this.modelText = () => {
      return this.model.value == 0 ? '' : `${this.model.text.replace(/\s\(\d+\)/g, '')}`
    }

    this.registrationText = () => {
      let registrationMonthFrom = this.registrationMonthFrom.value == 0 ? '' : `/${this.registrationMonthFrom.text}`
      let registrationYearFrom  = (this.registrationYearFrom.text.length == 0 || this.registrationYearFrom.value == 0)
                                  ? ''
                                  : `${this.registrationYearFrom.text}${registrationMonthFrom}- `
      let registrationMonthTo   = this.registrationMonthTo.value == 0 ? '' : `/${this.registrationMonthTo.text}`
      let registrationYearTo    = (this.registrationYearTo.text.length == 0 || this.registrationYearTo.value == 0)
                                  ? ''
                                  : ` ${this.registrationYearTo.text}${registrationMonthTo}`
      let hyphen = registrationYearFrom == '' && registrationYearTo ? '-' : ''
      return `${registrationYearFrom}${hyphen}${registrationYearTo}`
    }

    this.mileageText = () => {
      let mileageFrom = (this.mileageFrom.text.length == 0 || this.mileageFrom.value == 0) ? '' : `${this.mileageFrom.text}-`
      let mileageTo = this.mileageTo.value == 0 ? '' : this.mileageTo.text
      let hyphen = mileageFrom == '' && mileageTo ? '-' : ''
      return `${mileageFrom}${hyphen}${mileageTo}`
    }

    this.fobText = () => {
      let fobMin = this.fobMin.value == 0 ? '' : `${this.fobMin.text}-`
      let fobMax = this.fobMax.value == 0 ? '' : this.fobMax.text
      let hyphen = fobMin == '' && fobMax ? '-' : ''

      return `${fobMin}${hyphen}${fobMax}`
    }

    this.capacityText = () => {
      let capacityFrom = this.capacityFrom.value == 0 ? '' : `${this.capacityFrom.text}-`
      let capacityTo = this.capacityTo.value == 0 ? '' : this.capacityTo.text
      let hyphen = capacityFrom == '' && capacityTo ? '-' : ''
      return `${capacityFrom}${hyphen}${capacityTo}`
    }

    this.transmissionText = () => {
      return this.transmissions === [] ? '' :this.transmissions.map(item => item.text).join(', ')
    }

    this.accidentText = () => this.accident?.value == 0 ? '' : this.accident?.text
    this.steeringText = () => this.steering?.value == 0 ? '' : this.steering?.text
    this.conditionNewCarText = () => this.conditionNewCar.value === 1 ? this.conditionNewCar.text : ''
    this.regularCarText = () => this.regularCar.value === 1 ? this.regularCar.text : ''
    this.specialPriceText = () => this.specialPrice.value === 1 ? this.specialPrice.text : ''
    this.fueltypeText = () => {
      return this.fueltypes === [] ? '' :this.fueltypes.map(item => item.text).join(', ')
    }

    this.driveTypeText = () => this.driveType?.value == 0 ? '' : this.driveType?.text
    this.colorsText = () => this.colors === [] ? '' : this.colors.map(item => item.text).join(', ')
    this.doorText = () => (this.door.text.length == 0 || this.door?.value == 0) ? '' : `${this.door?.text} Doors`

    this.bodytypeText = () => {
      let text = this.bodytypes.map(item => item.text)

      if (this.subBodytypes.length > 0) {
        let text2 = text
        let subBody = []

        this.subBodytypes.forEach(element => {
          if (element.value === '0') return
          let mainBt = element.mainBt
          text2 = text2.filter(e => e !== mainBt)
          subBody.push(`${mainBt} ${element.text}`)
        });

        return subBody.concat(text2).join(', ');
      } else {
        return text.join(', ');
      }
    }
  }
}

const getParamOnUrl = (key) => {
  const url = new URL(window.location.href)

  return decodeURIComponent(url.searchParams.get(key))
}
