export const getQueryParamsFromURL = () => {
  const arrKeys = ['tmns', 'fues', 'bsty', 'ecls']
  const numberRegex = /^\d+$/

  let makeModelObject = {}
  let paramsOnPath = document.location.pathname.replace(/%20/g, ' ').split('/')
  let queryParams = Object.fromEntries(new URLSearchParams(document.location.search))

  let makeModelArray = [
    { key: 'make', value: paramsOnPath[2] || queryParams['make'] && queryParams['make'].replace(/[+]/g, ' ') },
    { key: 'model', value: paramsOnPath[3] || queryParams['model'] && queryParams['model'].replace(/[+]/g, ' ')}
  ]

  makeModelArray.forEach(el => {
    if (el.value !== undefined && el.value?.length !== 0 && el.value !== 'all') {
      makeModelObject[el.key] = el.value
    }
  })

  delete queryParams['make']
  delete queryParams['model']

  arrKeys.forEach((key) => {
    if (queryParams[key]) {
      let value = queryParams[key].replaceAll(/\*|\./g, '')
      queryParams[key] = value.match(numberRegex) ? queryParams[key].split('*') : undefined
    }
  })

  return Object.assign({}, queryParams, makeModelObject)
}
