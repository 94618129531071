import Cookies from 'js-cookie'

export default function sendRegisterCouponApi() {
  const modalCoupon = $('#modal-coupon-prevent-leaving')

  modalCoupon.modal('show')
  Cookies.set('coupon_was_used', 'true', { expires: 1 })

  $.ajax({
    type: 'POST',
    url: '/ajax_v2/precoupon_register',
    data: { type:  modalCoupon.attr('data-value') },
  })
}

export function clickOutsideModalAssist() {
  const popupAssist = $('.tcv-modal-popup-assist')

  popupAssist.on("click", function (event) {
    if (!$(event.target).closest("#modal__popup--assist").length) {
      const notShow = $("#do-not-show--assist").is(":checked");
      if (notShow) {
        Cookies.set("not_show_popup_assist", "true", { expires: 1 });
      }
      event.stopPropagation();
      if ($("#modal-coupon-prevent-leaving").length > 0 && !Cookies.get('coupon_was_used')) {
        sendRegisterCouponApi()
      }
      popupAssist.hide()
    }
  });
}
