import Glide from '@glidejs/glide'

export function setupImageSlider () {
  const backOrderSlide = document.querySelector('.backorder-glide-slide')

  if (backOrderSlide) {
    new Glide('.glide', {
      type: 'carousel',
      perView: 1,
      autoplay: 5000,
      gap: 0
    }).mount();
  }
}
