import { Controller } from 'stimulus'
import Cookies from 'js-cookie';

export default class extends Controller {
  static targets = ['gdprTopPage']

  initialize() {
    if (Cookies.get('hasApproveCookiesUse') == 'true') {
      this.gdprTopPageTarget.remove()
    }
  }

  setCookieForDisplayGDPR() {
    Cookies.set('hasApproveCookiesUse', 'true', { expires: 730, path: '/' })
    this.gdprTopPageTarget.remove();
  }
}
