(function() {
  $(document).ready(function() { bindAction(); });

  var bindAction = function() {
    $(window).scroll(function () { 
      if (window.pageYOffset >= 940) {
        $('.scroll-to-top-btn').addClass('active');
      } else {
        $('.scroll-to-top-btn').removeClass('active');
      }
    });

    $('.scroll-to-top-btn').on('click', function () {
      $('html, body').animate({ scrollTop: 0 }, 'smooth');
    });
  }
}).call(this);
