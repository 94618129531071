const EMAIL_FORMAT = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
const EMAIL_FORMAT_SPECIAL_CHARACTER = /\w+([-+.]|\w)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
const NUMBER_FORMAT = /^\d{7,15}$/
const PASSWORD_FORMAT = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[a-zA-Z\d.-@+#/!]{6,20}$/

export const signUpValidateField = (target, nextTarget = []) => {
  let field = target.dataset.inputField
  let value = target.value
  let check = false

  check = (field === 'email' && value.match(EMAIL_FORMAT) && value.match(EMAIL_FORMAT_SPECIAL_CHARACTER)) ||
          (field === 'number' && value.match(NUMBER_FORMAT)) ||
          (field === 'purpose' && value !== '0') ||
          (field === 'password' && value.match(PASSWORD_FORMAT)) ||
          (field === 'country' && value !== '')

  if (check) {
    target.classList.remove('error')

    if (nextTarget.length) {
      nextTarget.forEach(
        (target) => target.classList.add('visible')
      )

      const inputField = document.querySelector(`.${field}-input-field`)
      const inputBox = document.querySelector(`.${field}-input-box`)

      inputBox.classList.add('d-none')
      inputField.classList.remove('d-none')
      inputField.nextSibling.classList.remove('d-none')

      switch(field) {
        case 'email':
          inputField.querySelector('.input-text').textContent = value
          break
        case 'number':
          const countryCodeSelector = document.querySelector('#country_code')
          const selectedIndex = countryCodeSelector.selectedIndex
          inputField.querySelector('.input-text').textContent = `${countryCodeSelector[selectedIndex].text} ${value.slice(0, -4)}****`
          break
        case 'password':
          inputField.querySelector('.input-text').textContent = '**********'
          inputField.querySelector('input#show_password_field').checked = false
          nextTarget[0].querySelector('.ok-message').classList.remove('d-none')
          break
        case 'country':
          inputField.querySelector('.input-text').textContent = target[target.selectedIndex].text
          break
      }
    }
  } else {
    target.classList.add('error')
  }
}

export const signUpvalidateFieldWhenSubmitting = (obj, callback, isPC = false) => {
  let email = obj.ipEmailTxtTarget
  let number = obj.ipNumberTxtTarget
  let purpose = isPC ? obj.ipPurposeTxtTarget : obj.ipPurposeTxtTargets
  let password = obj.ipPasswordTxtTarget
  let countryCode = obj.countryCodeTarget;
  let countryNum = obj.countryNumTarget;
  let errorMessage = obj.errorMessageTarget;
  let isValid = true
  let captchaResponse = grecaptcha.getResponse()

  if (email.value.match(EMAIL_FORMAT) && email.value.match(EMAIL_FORMAT_SPECIAL_CHARACTER)) {
    email.classList.remove('error')
  } else {
    email.classList.add('error')
    isValid = false
  }

  if (number.value.match(NUMBER_FORMAT)) {
    number.classList.remove('error')
  } else {
    number.classList.add('error')
    isValid = false
  }

  if (isPC) {
    if (purpose.value !== '0') {
      purpose.classList.remove('error')
    } else {
      purpose.classList.add('error')
      isValid = false
    }
  }

  if (password.value.match(PASSWORD_FORMAT)) {
    password.classList.remove('error')
  } else {
    password.classList.add('error')
    isValid = false
  }

  if (countryCode.value === '') {
    countryCode.classList.add('error')
    isValid = false
  } else {
    countryCode.classList.remove('error')
  }

  if (!isPC) {
    const newInputPurpose = document.querySelector('.input-purpose')

    if (newInputPurpose) {
      if (!purpose.find((e) => e.checked)) {
        document.querySelector('.input-purpose').classList.add('error')
        isValid = false
      } else {
        document.querySelector('.input-purpose').classList.remove('error')
      }
    }
  }

  if (captchaResponse.length === 0) {
    if (isPC || (!isPC && !$('.recaptcha-chatbox').hasClass('d-none'))) {
      isValid = false
      errorMessage.innerHTML = 'Authentification failed. Please try again.';
      $('.send-button').addClass('inactive')
      $('.gcaptcha-error-message').show()
    }
  } else {
    $('.send-button').removeClass('inactive')
    $('.gcaptcha-error-message').hide()
  }

  if (isValid) {
    const data = {
      email: email.value,
      password: password.value,
      country: countryCode.value,
      country_code: countryNum.value,
      number: number.value,
      purpose: isPC ? purpose.value : purpose.find((e) => e.checked).value,
      recaptcha_response: captchaResponse
    }

    callback(data)
  }
}
