import { Controller } from 'stimulus'
import { ajax } from '@rails/ujs'
import moment from 'moment-timezone'
import { getDataURLSearchParams } from '../shared/url_search_param'
import passAjaxInvalidAuthenticityToken from '../shared/authenticity_hanlder'

export default class extends Controller {
  connect() {
    this.loadJapanTime();
  }

  loadJapanTime() {
    let currentTime = moment().tz('Asia/Tokyo').format('MMM / DD / YYYY hh:mm A');
    $('.japan-time .current-time').html(currentTime + ' (JST)');
    setTimeout(() => this.loadJapanTime(), 60000);
  }

  changeCurrency(e) {
    let data = { currency: e.target.value };

    ajax({
      url: '/ajax_v2/currencies',
      type: 'POST',
      dataType: 'jsonp',
      data: getDataURLSearchParams(data),
      success: function (response) {
        window.location.reload();
      },
      error: function(response) { passAjaxInvalidAuthenticityToken(response) }
    })
  }
}
