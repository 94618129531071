import { ajax } from '@rails/ujs'

export function getSellerInfo(wrapper) {
  ajax({
    url: wrapper.sellerInfoAreaTarget.dataset.carSellerUrl,
    type: 'GET',
    dataType: 'jsonp',
    success: function(response) {
      wrapper.sellerInfoAreaTarget.outerHTML = response.seller_info

      if (wrapper.hasSellerNameRatingTarget) {
        wrapper.sellerNameRatingTarget.outerHTML = response.seller_rating
      }
    }
  })
}
