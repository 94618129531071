// app/javascript/controllers/index.js

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

const application = Application.start()
const context = require.context('controllers/shared', true, /_controller\.js$/)
application.load(definitionsFromContext(context))

// load js not use stimulus here
// file name doesn't has _controller
// ex: import './sample'
import './modal'
import './affiliate_popup'
import './check_year_model'
import bindActionSetCookiesViewport from './cookies_viewport'
bindActionSetCookiesViewport()
import '../shared/referrer_detector'
