// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

import 'controllers/shared'
import 'controllers/pc'
import 'stylesheets/pc_application'
import SearchParams from '../contructors/search_params.js'
global.searchParams = new SearchParams({});

Rails.start()
// Turbolinks.start()
// ActiveStorage.start()
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
import Cookies from 'js-cookie';
Cookies.remove('tcvcookies', { domain: `.${document.location.hostname}` })
Cookies.remove('fgkey', { domain: `.${document.location.hostname}` })
Cookies.remove('hasF', { domain: `.${document.location.hostname}` })
