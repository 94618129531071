import Cookies from "js-cookie"

(function() {
  $(document).ready(function() {
    const checkIfReferrerIsCurrentHost = document.referrer.includes(window.location.hostname)

    if (!checkIfReferrerIsCurrentHost) {
      sessionStorage.setItem("referrer", document.referrer)
      Cookies.set('referrer_url', sessionStorage.getItem('referrer'), { expires: 30 })
    }

    if (!sessionStorage.getItem("referrer") && checkIfReferrerIsCurrentHost && Cookies.get('referrer_url')) {
      sessionStorage.setItem("referrer", Cookies.get('referrer_url'))
    }
  });
}).call(this)
