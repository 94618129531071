import { TARGET_COUNTRY_CODE, BNPL_PATH } from "./constants"

export function activeTab(target) {
  const tabEls = target.closest('.tabs').querySelectorAll('.tabs__tab')
  const targetTabContent = document.querySelector(`.tab-content [data-tab-content="${target.dataset.tab}"]`)
  const tabContentEls = targetTabContent.closest('.tab-content').querySelectorAll('.tab-content__block')

  // Hide all tab, tab-content elements
  tabEls.forEach(el => el.classList.remove('tabs__tab--active'))
  tabContentEls.forEach(el => el.classList.remove('tab-content__block--active'))
  // Show target element
  target.classList.add('tabs__tab--active')
  targetTabContent.classList.add('tab-content__block--active')
}

export function enableFinanceTab() {
  const ettSelectCountry = parseInt($('#ett_select_country').val())
  const financeTab = document.querySelector('#finance_tab')
  const alert = document.querySelector('.finance-alert')
  const learnMore = $('.learn_more_bnpl')

  if (TARGET_COUNTRY_CODE.includes(ettSelectCountry)) {
    financeTab.disabled = false
    financeTab.classList.remove('tabs__tab--disabled')
    alert.classList.add('d-none')
    learnMore.attr('href', BNPL_PATH[ettSelectCountry])
    BNPL_PATH[ettSelectCountry] === undefined ? learnMore.addClass('d-none') : learnMore.removeClass('d-none')
  } else {
    financeTab.disabled = true
    financeTab.classList.add('tabs__tab--disabled')
    alert.classList.remove('d-none')
  }
}
