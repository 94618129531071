import { ajax } from '@rails/ujs'

export default function fetch_frame_user_reviews(elTarget) {
  ajax({
    url: elTarget.dataset.url,
    type: 'GET',
    success: function (response) {
      if (response === '') return;
      elTarget.outerHTML = response.body.innerHTML
    }
  })
}
