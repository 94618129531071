import { ajax } from '@rails/ujs'
import passAjaxInvalidAuthenticityToken from '../shared/authenticity_hanlder'

export async function isUserLogin() {
  $('#overlay_loading').show()

  return new Promise(res => {
    ajax({
      url: `/ajax_v2/users/user_signed_in`,
      type: 'POST',
      dataType: 'json',
      success: ((response) => {
        $('#overlay_loading').hide()
        res(response.is_signed_in)
      }),
      error: function(response) {
        passAjaxInvalidAuthenticityToken(response)
        res(response)
      }
    })
  })
}
