(function() {
  $(document).ready(function() {
    init();
  });

  var init = function() {
    checkToShowAffiliatePopup();
  }

  async function checkToShowAffiliatePopup() {
    await $.ajax({
      url: '/ajax_v2/promotions/check_affiliate',
      type: 'get',
      data: { current_path: window.location.pathname },
      async: true,
      success: function (response) {
        if (response.show_affiliate === true) {
          $("#modal-affiliate").modal()
        }
      }
    })
  }
}).call(this);
