// app/javascript/controllers/index.js

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

const application = Application.start()
const context = require.context('controllers/pc', true, /_controller\.js$/)
application.load(definitionsFromContext(context))

// load js not use stimulus here
// file name doesn't has _controller
// ex: import './sample'
import './page_list'
import '../../components/scroll_to_top'

import { fetch_amount_notify } from '../shared/messages_controllable'
fetch_amount_notify(amount => {
  if (amount > 0)
    $('#mes-num--top-header').text(amount)
    $('#mes-num--dropdown').removeClass('d-none')
    $('#mes-num--dropdown').text(`(${amount})`)
})
