import { ajax } from '@rails/ujs'
import { getDataURLSearchParams } from './url_search_param'

export function getInquiryCars(carIDs) {
  const data = { car_ids: JSON.stringify(carIDs) }

  ajax({
    url: '/ajax_v2/inquiries_cars',
    data: getDataURLSearchParams(data),
    type: 'GET',
    dataType: 'jsonp',
    success: function(response) {
      const inquiriesCars = response.inquiries_counter

      Object.keys(inquiriesCars).forEach((carId) => {
        let inquiryCar = document.querySelector(`[data-car-inquiry-id='${carId}']`)
        inquiryCar.querySelector('.js-inquiry_number').textContent = inquiriesCars[carId]
        inquiryCar.classList.remove('d-none')
      })
    }
  })
}

export function fetchInquiriesCounter(target, callback) {
  ajax({
    url: target.dataset.url,
    type: 'GET',
    success: function (response) {
      target.outerHTML = response.body.outerHTML
      callback?.()
    }
  })
}
