(function() {

  $(document).ready(function() {
    init();
  });

  var init = function() {
    closeModal();
    defineModal();
    handleClickOutsideModel();
  }

  var handleClickOutsideModel = function () {
    $(document).on("click", function (event) {
      if (!$(event.target).closest(".tcv-modal .modal-content").length) {
        $(".tcv-modal").modal("hide");
      }
    });
  }

  var defineModal = function() {
    $.fn.modal = function(type = "show") {
      const self = this;
      if (type === "show") {
        $(this).show();
        setTimeout(function () {
          self.find(".modal-dialog").addClass("show")
        }, 20);
      } else {
        $(this).hide();
        setTimeout(function () {
          self.find(".modal-dialog").removeClass("show")
        }, 20);
      }
      return this;
    };
  }

  var closeModal = function() {
    $(".tcv-modal .icon-close").on("click", function() {
      $(".tcv-modal").modal("hide");
    })
  }

}).call(this);
