import {ajax} from '@rails/ujs';
import passAjaxInvalidAuthenticityToken from '../controllers/shared/authenticity_hanlder'
import Cookies from 'js-cookie';
import { getDataURLSearchParams } from '../controllers/shared/url_search_param'

export default function favorite_action(wrapperTarget, setEventGlobal = false) {
  const targets = wrapperTarget.querySelectorAll('[data-button-name="addFavorite"]')
  targets.forEach(target => {
    target.addEventListener('click', function (e) {
      e.stopPropagation()

      let fnstring = e.currentTarget.dataset.buttonAction
      switch (fnstring) {
        case 'showModalSignIn': showModalSignIn(e.currentTarget); break;
        case 'addFavorite': addFavorite(e.currentTarget); break;
        case 'redirectToLogin': redirectToLogin(e.currentTarget); break;
      }
    })
  })

  favTooltipAction(wrapperTarget)

  if (setEventGlobal) {
    setModalAction()
    document.addEventListener('click', () => removeCurrentTooltip())
  }
}

export const addOrRemoveCarFavorite = async (carId, method) => {
  return new Promise(res => {
    return ajax({
      url: `/ajax_v2/register_favorite/${carId}`,
      type: method,
      dataType: 'json',
      success: ((response) => res(response)),
      error: function(response) { passAjaxInvalidAuthenticityToken(response) }
    })
  })
}

export function changeFavorite(event, self, isSearchPage = false) {
  event.preventDefault()
  event.stopPropagation()

  const currentTarget = event.currentTarget
  const carId = currentTarget.parentElement.dataset.carId
  const button = currentTarget.dataset.searchFavorite
  const method = button === 'off' ? 'POST' : 'DELETE'
  const jpCountryCode = 392
  const target = $(`.favorite__${button}[data-favorite-id='${carId}']`)

  currentTarget.style.cursor = 'none'

  method === 'POST' ? addToFavorite(target) : removeFromFavorite(target);

  addOrRemoveCarFavorite(carId, method)
    .then((response) => {
      currentTarget.style.cursor = ''

      if (!response.success) {
        return method === 'POST' ? revertAddFavorite(target) : revertRemoveFavorite(target)
      }

      if(self.currentCountryIdValue !== jpCountryCode && !self.isClickFavoriteValue && !Cookies.get('not_show_popup_assist')){
        if (isSearchPage) history.pushState({}, '', window.location.href)
        self.isClickFavorite = true
      }
    }).catch(() => currentTarget.style.cursor = '')
}

export function loadFavorite(carIDs) {
  const data = { car_ids: JSON.stringify(carIDs) }

  ajax({
    url: '/ajax_v2/favorite_cars',
    data: getDataURLSearchParams(data),
    type: 'GET',
    dataType: 'jsonp',
    success: function (response) {
      const favoriteCarIds = response.favorite_car_ids;
      const favoriteButtons = favoriteCarIds.map(carId => `.favorite__on[data-favorite-id='${carId}']`).join(', ')
      const unfavoriteButtons = favoriteCarIds.map(carId => `.favorite__off[data-favorite-id='${carId}']`).join(', ')

      $(favoriteButtons).removeClass('inactive');
      $(unfavoriteButtons).addClass('inactive');
    }
  });
}

const addToFavorite = (target) => {
  target.addClass("inactive")
  target.next().removeClass("inactive")
}

const removeFromFavorite = (target) => {
  target.addClass("inactive")
  target.prev().removeClass("inactive")
}

const revertAddFavorite = (target) => {
  target.removeClass('inactive')
  target.next().addClass('inactive')
}

const revertRemoveFavorite = (target) => {
  target.removeClass('inactive')
  target.prev().addClass('inactive')
}

const showModalSignIn = (el) => {
  if (el.classList.value.includes('inactive')) return;

  removeCurrentTooltip()
  el.nextElementSibling.classList.add('appearance')
}

const addFavorite = (btnFavorite) => {
  var btnFavoriteBottom = null
  var btnFavoriteBody = null
  const shade = document.querySelector('[data-shade-name="addToFav"]')
  const modal = document.querySelector('[data-modal-name="addToFav"]')
  const carId = btnFavorite.dataset.carId

  if (btnFavorite.classList.contains('btn-add-fav')) {
    btnFavoriteBody = btnFavorite
    btnFavoriteBottom = document.querySelector('button.add-to-favorite')
  } else {
    btnFavoriteBottom = btnFavorite
    btnFavoriteBody = document.querySelector('button.btn-add-fav')
  }

  btnFavoriteBody.classList.add('inactive')
  if (btnFavoriteBottom) btnFavoriteBottom.disabled = true

  addOrRemoveCarFavorite(carId, 'POST')
    .then((response) => {
      if (response.success) {
        showModal(shade, modal)
      } else {
        if (response.error) {
          confirm('Sorry! Your session has expired, please login again.')
          window.location.reload();
        }

        btnFavoriteBody.classList.remove('inactive')
        if (btnFavoriteBottom) btnFavoriteBottom.disabled = false
      }
    })
}

const redirectToLogin = (btnFavorite) => {
  const loginUrl = btnFavorite.dataset.loginUrl
  window.location.href = loginUrl
}

const favTooltipAction = (wrapperTarget) => {
  const btnCloseLists = wrapperTarget.querySelectorAll('span.icon-close')

  btnCloseLists.forEach(btnClose => {
    btnClose.addEventListener('click', (e) => {
      e.stopPropagation()
      e.target.parentElement.classList.remove('appearance')
    })
  })
}

const removeCurrentTooltip = () => {
  const currentTooltip = document.querySelector('.add-favorite-tooltip.appearance')
  if(currentTooltip) currentTooltip.classList.remove('appearance')
}

const setModalAction = () => {
  const shade = document.querySelector('[data-shade-name="addToFav"]')
  const modal = document.querySelector('[data-modal-name="addToFav"]')
  const btnClose = document.querySelector('[data-modal-action="closeModalFav"]')

  shade.addEventListener('click', (e) => hideModal(shade, modal))
  btnClose.addEventListener('click', (e) => hideModal(shade, modal))
}

const hideModal = (shade, modal) => {
  shade.classList.remove('appearance')
  modal.classList.remove('appearance')
}

const showModal = (shade, modal) => {
  shade.classList.add('appearance')
  modal.classList.add('appearance')
}
