import { Controller } from 'stimulus'
import { update_model_select_when_change_maker,
         fill_search_form_data,
         pull_maker_model_select_option,
         pull_select_option } from '../shared/data_loader'
import * as backorder from '../shared/backorder'

export default class extends Controller {
  static targets = [
    'makeSelector',
    'regisYearSelector',
    'bodystyleSelector',
    'excolorSelector',
    'bodystyle',
    'nameError',
    'emptyPhoneError',
    'invalidPhoneError',
    'descriptionError',
    'selectedFileText',
    'modelSelector',
    'nameInput',
    'phoneInput',
    'descriptionInput',
    'mileage',
    'form',
    'fileError',
    'budget',
    'countrySelector',
    'file',
    'lowestPrice'
  ]

  connect() {
    fill_search_form_data((res) => {
      pull_maker_model_select_option(res.maker_options, res.model_options)
      update_model_select_when_change_maker()
      pull_select_option(res, ['fid', 'bsty', 'ecls'])
    }, '/ajax_v2/backorders/data')

    this.fetchModals()
  }

  validateField(e) {
    const field = e.target.dataset.field

    backorder[`validate${field}`](e, this)
  }

  handleKeyPress(e) {
    backorder.handleKeyPress(e)
  }

  fetchModals() {
    const elTarget = $(".modal-list");
    if (elTarget.length) {
      $.ajax({
        url: '/ajax_v2/search_fetch_modals',
        type: 'GET',
        success: function(response) {
          elTarget.append(response.modal_signin + response.modal_signup);

          $('.tcv-modal .icon-close').on('click', function() {
            $('.tcv-modal').modal('hide');
          })
        }
      })
    }
  }

  fetchLowestPrice(e) {
    backorder.fetchLowestPrice(this, e.target.dataset.updateMake)
  }
}
