export default async function load_target_view(wrapper, element, loadingTarget = '') {
  if (wrapper[`has${element[0].toUpperCase() + element.slice(1)}Target`]) {
    const target = wrapper[`${element}Target`]
    const url = target.dataset.url
    const loading = wrapper[loadingTarget]

    await $.ajax({
      url: url,
      type: 'GET',
      async: true,
      success: function (response) {
        if (loading) {
          loading.style.display = 'none'
        }

        if (response === '') return

        target.outerHTML = response
      }
    })
  }
}
