import { Controller } from 'stimulus'
import { update_model_select_when_change_maker, update_model_select,
         fill_search_form_data, pull_maker_model_select_option, pull_select_option } from '../shared/data_loader'

export default class extends Controller {
  connect() {
    fill_search_form_data((res) => {
      const filedLists = ['fid', 'jid', 'prcf', 'prct', 'sds', 'eds']
      pull_maker_model_select_option(res.maker_options, res.model_options)
      update_model_select_when_change_maker()
      update_model_select()
      pull_select_option(res, filedLists)
    })
  }
}
