(function() {
  $(document).ready(function() {
    init()
  })

  var init = function() {
    reselectDestination()
  }

  var handleConfirmInquire = function() {
    $("#modal-check-year .inquire-btn").on("click", function() {
      $("#modal-check-year").modal("hide")
    })
  }

  var reselectDestination = function() {
    $("#modal-check-year .reselect-btn").on("click", function() {
      $("#modal-check-year").modal("hide")
    })
  }
}).call(this)
