import Cookies from 'js-cookie'
import sendRegisterCouponApi from '../shared/modal_coupon'

export default function loadModalAssist() {
  
  const popupAssist = $('.tcv-modal-popup-assist').length ? $('.tcv-modal-popup-assist') : $('#modal__popup--assist')

  if ($('#modal__popup--assist').length) {
    setupPopUpAssistLeaving()
    closeModalAssist()
  }

  function closeModalAssist() {
    
    $('#btn-close-modal--assist').on("click", function(e) {
      const notShow = $("#do-not-show--assist").is(':checked')
      if (notShow) {
        Cookies.set('not_show_popup_assist', 'true', { expires: 1 })
      }
      e.stopPropagation()
      if ($("#modal-coupon-prevent-leaving").length > 0 && !Cookies.get('coupon_was_used')) {
        sendRegisterCouponApi()
      }
      popupAssist.hide()
    })
  }

  function setupPopUpAssistLeaving() {
    if (!Cookies.get('not_show_popup_assist') && !$('.tcv-modal-popup-assist').length) {
      let statePushed = false

      document.onclick = () => {
        if (statePushed) return
        statePushed = true
        history.pushState({}, '', window.location.href)
      }
    }

    window.onpopstate = () => {
      if (!Cookies.get('not_show_popup_assist') && window.location.hash == '') {
        popupAssist.show()
      }
    }
  }
}
