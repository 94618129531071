import { ajax } from '@rails/ujs'
import passAjaxInvalidAuthenticityToken from './authenticity_hanlder'
import { isUserLogin } from '../shared/users_api'

const MAX_ALLOW_SIZE = 5*1024*1024 // max allow size is 5MB
const PHONE_FORMAT = /^\d{7,20}$/
const ERR_TYPES = {
  timeout: 'timeout',
  generalErr: 'general_err',
  noInternet: 'no_internet'
}

export function validateName(e, obj) {
  const target = e.target
  const errorTarget = obj.nameErrorTarget

  target.value.trim() ? errorTarget.classList.add('d-none')
                      : errorTarget.classList.remove('d-none')
}

export function validatePhone(e, obj) {
  const value = e.target.value
  const emptyPhoneError = obj.emptyPhoneErrorTarget
  const invalidPhoneError = obj.invalidPhoneErrorTarget

  if (value) {
    emptyPhoneError.classList.add('d-none')

    value.match(PHONE_FORMAT) ? invalidPhoneError.classList.add('d-none')
                              : invalidPhoneError.classList.remove('d-none')
  } else {
    emptyPhoneError.classList.remove('d-none')
    invalidPhoneError.classList.add('d-none')
  }
}

export function validateDescription(e, obj) {
  const descriptionError = obj.descriptionErrorTarget

  e.target.value ? descriptionError.classList.add('d-none')
                 : descriptionError.classList.remove('d-none')
}

export function validateFile(e, obj) {
  const target = e.target
  const file = target.files[0]
  const fileError = obj.fileErrorTarget
  const selectedFileText = obj.selectedFileTextTarget

  if (file.size > MAX_ALLOW_SIZE) {
    target.value = ''
    selectedFileText.textContent = 'No file chosen'
    fileError.classList.remove('d-none')
  } else {
    const fileName = file.name.length > 20 ? `${file.name.slice(0, 9)}...${file.name.slice(-10)}`
                                           : file.name

    selectedFileText.textContent = fileName
    fileError.classList.add('d-none')
  }
}

export function validateOnSubmitting(e, obj) {
  e.preventDefault()

  const name = obj.nameInputTarget.value.trim()
  const phone = obj.phoneInputTarget.value
  const description = obj.descriptionInputTarget.value
  let isValid = true
  let firstTargetWithError

  const errorName = obj.nameErrorTarget
  const emptyPhoneError = obj.emptyPhoneErrorTarget
  const invalidPhoneError = obj.invalidPhoneErrorTarget
  const descriptionError = obj.descriptionErrorTarget

  if(name) {
    errorName.classList.add('d-none')
  } else {
    isValid = false
    firstTargetWithError = firstTargetWithError || obj.nameInputTarget

    errorName.classList.remove('d-none')
  }

  if(phone) {
    emptyPhoneError.classList.add('d-none')

    if (phone.match(PHONE_FORMAT)) {
      invalidPhoneError.classList.add('d-none')
    } else {
      isValid = false
      firstTargetWithError = firstTargetWithError || obj.phoneInputTarget

      invalidPhoneError.classList.remove('d-none')
    }
  } else {
    isValid = false
    firstTargetWithError = firstTargetWithError || obj.phoneInputTarget

    emptyPhoneError.classList.remove('d-none')
  }

  if (description) {
    descriptionError.classList.add('d-none')
  } else {
    isValid = false
    firstTargetWithError = firstTargetWithError || obj.descriptionInputTarget

    descriptionError.classList.remove('d-none')
  }

  if (isValid) {
    const data = new FormData(obj.formTarget)

    createBackOrder(data)
  } else {
    firstTargetWithError.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

export function handleKeyPress(e) {
  if (e.keyCode === 13) {
    e.target.blur()
  }
}

function createBackOrder(formData) {
  isUserLogin().then((isUserSignedIn) => {
    // when no internet connection
    if (isUserSignedIn === '') {
      handleError('no_internet')
      return
    }
    // if loggedin
    if (isUserSignedIn) {
      submitBackOrder(formData)
    } else {
      if ($('.wrapper__pc').length === 1) {
        // for PC
        $('#modal-signup').hide()
        $('#modal-signin').modal()
      } else {
        // for SP
        sendDataToSimpleLogin(formData)
      }
    }
  })
}

const sendDataToSimpleLogin = function(formData) {
  let url = new URL(`${window.location.origin}/simple/loginregist`);
  formData.forEach(function(value, key) {
    if (key === 'file') {
      url.searchParams.set(key, value.name);
    } else {
      url.searchParams.set(key, value);
    }
  });

  url.searchParams.set('type', 'backorder');
  const backOrderFile = formData.get('file')
  if (backOrderFile.size) {
    const reader = new FileReader();
    reader.readAsDataURL(backOrderFile);
    reader.onload = function () {
      localStorage['backOrderFile'] = reader.result
    }
  }
  window.location.href = url.toString()
}

const submitBackOrder = function(data) {
  $('#overlay_loading').show()

  ajax({
    url: '/ajax_v2/backorders/create',
    data: data,
    type: 'POST',
    success: function() {
      window.location.href = '/backorder/thanks'
      $('#overlay_loading').hide()
    },
    error: function(response) {
      handleError(response['type'] || 'no_internet')
      passAjaxInvalidAuthenticityToken(response)
    }
  })
}

const handleError = function(type) {
  let message = ''
  switch(type) {
    case ERR_TYPES['timeout']:
      message = 'Our systems are currently overloaded. Please try again in a few minutes.'
      break
    case ERR_TYPES['generalErr']:
      message = 'System Error. Please contact administrator.'
      break
    case ERR_TYPES['noInternet']:
      message = 'Slow or no internet connection. Please check your internet settings'
  }

  if (confirm(message) == true) {
    $("#overlay_loading").hide()
  } else {
    window.location.href = '/errorpage'
  }
}

export function fetchLowestPrice(obj, updateMake = false) {
  const lowestPrice = obj.lowestPriceTarget
  const url = lowestPrice.dataset.url
  const formData = new FormData(obj.formTarget)

  const data = {
    make: formData.get('make'),
    model: updateMake ? null : formData.get('model'),
    preposition: formData.get('preposition'),
    year: formData.get('fid')
  }

  $.ajax({
    url: url,
    type: 'GET',
    data: data,
    success: function(response) {
      lowestPrice.innerHTML = response
    }
  })
}
