import { Controller } from "stimulus";
import Cookies from 'js-cookie';
import { setOfferErrorMessage } from "../shared/thankyou_error_handle";
import passAjaxInvalidAuthenticityToken from '../shared/authenticity_hanlder'

const COOKIE_NAME = 'loginribbonnew'
export default class extends Controller {
  static targets = ['tabSerial', 'rbSerialColor', 'dSignInBodyColor', 'dSignInBody', 'lbSerialTxt', 'ipColorTxt',
                    'dSignInInput', 'dBlockLoader', 'btnSend', 'emailInput', 'passwordInput']

  connect() {
    document.body.addEventListener('click', () => this.hideCurrentTooltip())

    this.inputSigninHandler();
    this.showSerialSignIn();
  }

  inputSigninHandler() {
    const signInInputs = this.dSignInInputTarget.querySelectorAll('input[type=text], input[type=password]')
    signInInputs.forEach((ipTarget) => {
      ipTarget.addEventListener('focus', () => this.resetSignInButton() )
    })
  }

  openTooltip(e) {
    e.stopPropagation();
    const currentTarget = e.currentTarget
    const newtooltip = currentTarget.parentElement.querySelector('.block-tooltip')
    const btnClose = newtooltip.querySelector('button.icon-close-tooltip')
    this.hideCurrentTooltip()

    newtooltip.classList.add('appearance')
    btnClose.setAttribute('data-action', 'click->signin#closeTooltip')
  }

  closeTooltip(e) {
    e.stopPropagation();
    e.currentTarget.closest('.block-tooltip.appearance').classList.remove('appearance')
  }

  hideCurrentTooltip() {
    const currentTooltip = document.querySelector('.block-tooltip.appearance')
    if (currentTooltip) { currentTooltip.classList.remove('appearance') }
  }

  openSerialTab() {
    this.tabSerialTarget.classList.add('appearance')
  }

  closeSerialTab() {
    this.tabSerialTarget.classList.remove('appearance')
  }

  showSerialSignIn() {
    if (Cookies.get(COOKIE_NAME)) {
      let signInValue = Cookies.get(COOKIE_NAME).split('&');
      this.showSignInBodyColor(signInValue[1].split('=')[1], signInValue[0].split('=')[1])
    } else {
      this.showSignInBody();
    }
  }

  showSignInBodyColor(text, color) {
    const signInBodyColor = this.dSignInBodyColorTarget
    this.dSignInBodyTarget.classList.add('d-none')
    signInBodyColor.classList.remove('d-none')
    this.lbSerialTxtTarget.textContent = text
    signInBodyColor.style.backgroundColor = color

    this.ipColorTxtTarget.value = text
    Array.from(this.rbSerialColorTargets).map((rbColor) => {
      if (rbColor.value === color) rbColor.checked = true
    })
  }

  showSignInBody() {
    this.dSignInBodyTarget.classList.remove('d-none')
    this.dSignInBodyColorTarget.classList.add('d-none')
  }

  createSerialCookie() {
    let word = this.ipColorTxtTarget.value
    let color = Array.from(this.rbSerialColorTargets).find((rbColor) => rbColor.checked ).value

    this.showSignInBodyColor(word, color);
    let cookieValue = 'color=' + color + '&' + 'word=' + word
    Cookies.set(COOKIE_NAME, cookieValue, { expires: 365 })
    this.closeSerialTab()
  }

  deleteSerialCookie() {
    Cookies.remove(COOKIE_NAME)
    this.closeSerialTab()
    this.showSignInBody()
  }

  toggleSignInDisplay(displayInput = true) {
    if (displayInput) {
      this.dSignInInputTarget.classList.remove('d-none')
      this.dBlockLoaderTarget.classList.remove('active')
    } else {
      this.dSignInInputTarget.classList.add('d-none')
      this.dBlockLoaderTarget.classList.add('active')
    }
  }

  handleSignIn() {
    const self = this;
    self.toggleSignInDisplay(false)

    $.ajax({
      url: '/ajax_v2/users/signin',
      type: 'POST',
      data: {
        email: self.emailInputTarget.value,
        password: self.passwordInputTarget.value,
        remember_me: $('#remember_me').is(':checked')
      },
      success: function (response) {
        self.toggleSignInDisplay();

        if (response.user.UserID != 0) {
          $('#modal-signin').hide()
          $('#overlay_loading').show()
          $('body').data('logged', true)

          const offerInfo = $('#modal-signup').data('offer-info')
          const backOrderInfo = $('.backorder-form')[0]

          if (offerInfo) {
            const dataJson = JSON.parse(offerInfo)
            self.createOffer(dataJson)
          }

          if (backOrderInfo) {
            const backOrderData = new FormData(backOrderInfo);

            self.submitBackOrder(backOrderData)
          }
        } else {
          self.btnSendTarget.classList.add('inactive');
        }
      },
      error: function(response) { passAjaxInvalidAuthenticityToken(response) }
    });
  }

  createOffer(dataJson) {
    const dataOffer = {
      referrer: sessionStorage.getItem('referrer'),
      ...dataJson
    }

    $.ajax({
      url: '/ajax_v2/transactions/offers',
      type: "POST",
      data: dataOffer,
      success: function(data) {
        $('#overlay_loading').hide()
        setOfferErrorMessage(data)
        window.location.href = data.url
      },
      error: function(response) { passAjaxInvalidAuthenticityToken(response) }
    });
  }

  submitBackOrder(backOrderData) {
    $.ajax({
      url: '/ajax_v2/backorders/create',
      data: backOrderData,
      type: 'POST',
      processData: false,
      contentType: false,
      success: function() {
        window.location.href = '/backorder2/thanks'
        $('#overlay_loading').hide()
      },
      error: function(response) {
        passAjaxInvalidAuthenticityToken(response)
      }
    })
  }

  resetSignInButton() {
    this.btnSendTarget.classList.remove('inactive')
  }

  openSignUpModal() {
    $('#modal-signin').hide();
    $('#modal-signup').modal()
    const sitekey = $('#recaptcha')[0].dataset.sitekey

    try {
      grecaptcha.render('recaptcha', {'sitekey': sitekey})
    } catch {
      return
    }
  }
}
