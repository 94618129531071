import { setOfferErrorMessage } from '../shared/thankyou_error_handle'
import { EMAIL_REGEX, CONFIRM_MSG } from '../shared/constants'

export default function setDefaultContactSeller() {
  const content = "Write your question here.\r\nYou can select Questions from the above templates or you can write freely."
  $("#modal-contact-seller .textarea-block textarea").val(content).css("font-size","12px").css("color","#999")
}

(function() {
  $(document).ready(function() {
    init();
  });

  let isShowTextDefault = true

  var init = function() {
    handleModifyTextarea()
    handleInputTextarea()
    handleClickOutsideTextarea()
    handleSubmitForm();
  }

  var handleSubmitForm = function() {
    $(".modal-list").delegate("form.form-send-block-wrap", "submit", function() {
      const messageContent = $("#modal-contact-seller .textarea-block textarea").val()
      if (messageContent.match(EMAIL_REGEX) && !confirm(CONFIRM_MSG)) return

      const dataOffer = JSON.parse($('#modal-contact-seller').data('offer-info'))

      const dataString = {
        message: isShowTextDefault ? "" : messageContent,
        receive_promotion: $("#ptid-receive-promotion").is(":checked"),
        referrer: sessionStorage.getItem("referrer"),
        ...dataOffer
      }

      $("#modal-contact-seller").hide();
      $('#overlay_loading').show()

      $.ajax({
        url: "/ajax_v2/transactions/offers",
        type: "POST",
        data: dataString,
        success: function(data) {
          $('#overlay_loading').hide()
          setOfferErrorMessage(data)
          window.location.href = data.url;
        },
        error: function(response) { passAjaxInvalidAuthenticityToken(response) }
      });

      return false;
    });
  }

  var defaultTextAreaContent = function() {
    setDefaultContactSeller()
    isShowTextDefault = true;
  }

  var handleClickOutsideTextarea = function() {
    $(document).on("click", function (event) {
      const textAreaElement = $("#modal-contact-seller .textarea-block textarea")
      if ($("#modal-contact-seller").length === 0) { return }
      if ($(event.target).closest("#modal-contact-seller .textarea-block textarea").length === 0) {
        if (textAreaElement.val().length > 0 && isShowTextDefault == false) {
          textAreaElement.css("font-size","").css("color","")
        } else {
          defaultTextAreaContent();
        }
      }
    });
  }

  var handleInputTextarea = function() {
    $(".modal-list").delegate("#modal-contact-seller .textarea-block textarea", "focus", function() {
      if (isShowTextDefault) {
        $(this).val("")
        isShowTextDefault = false
      }
    })
  }

  var handleModifyTextarea = function() {
    $(".modal-list").delegate("#modal-contact-seller ul.checkbox-block input[type='checkbox']", "click", function() {
      let theBuyerComment = "";
      if($("#modal-contact-seller #ptid-negotiate-price").prop("checked")){ theBuyerComment += "I want to negotiate the best price\n" }
      if($("#modal-contact-seller #ptid-know-schedule").prop("checked")){ theBuyerComment += "I want to know the shipping schedule\n" }
      if($("#modal-contact-seller #ptid-know-condition").prop("checked")){ theBuyerComment += "I want to know about the condition of the car\n" }

      if (theBuyerComment === "") {
        defaultTextAreaContent()
      } else {
        isShowTextDefault = false
        $("#modal-contact-seller .textarea-block textarea").val(theBuyerComment).css("font-size","").css("color","")
      }
    })
  }
}).call(this);
