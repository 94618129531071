export function showSectionWhenScolling(headerHeight, targetsAboveSection, obj) {
  const totalScrollHeight = targetsAboveSection.reduce(
    (acc, target) => acc + obj[`${target}Target`].clientHeight, headerHeight
  )

  const bodyHeight = document.body.clientHeight
  const isFixed = window.scrollY + bodyHeight <= totalScrollHeight

  obj.fixedFooterTarget.classList.toggle('js--fixed', isFixed)
}
