export function handleSubmitContactSeller(e, urlSigin = null) {
  e.stopPropagation()
  const manufactureYear = parseInt($('.car-detail-form .manufacture-year').val())
  const registrationYear = parseInt($('.car-detail-form .registration-year').val())

  const serverKind = $(".kind-from-server").val()
  const serverYear = $(".year-from-server").val()
  const serverBeforeYear = $(".before-year-from-server").val()
  const serverAlert = $(".insurance-alert:first").text()

  let isValid = validate(serverKind, serverYear, manufactureYear, registrationYear, serverAlert, serverBeforeYear)

  if (isValid) {
    return true
  } else {
    $("#modal-check-year .alert-content").html(serverAlert)
    $("#modal-check-year").modal()
    return false
  }
}

const validate = function(serverKind, serverYear, manufactureYear, registrationYear, serverAlert, serverBeforeYear) {
  const currentYear = new Date().getFullYear()
  if (serverAlert === '') return true
  if (serverKind == 'Manufacture') {
    if (manufactureYear === 0) return true
    if (serverYear) return currentYear - manufactureYear <= parseInt(serverYear)
    if (serverBeforeYear) return manufactureYear > parseInt(serverBeforeYear)
  } else {
    if (registrationYear === 0) return true
    if (serverYear) return currentYear - registrationYear <= parseInt(serverYear)
    if (serverBeforeYear) return registrationYear > parseInt(serverBeforeYear)
  }
}
